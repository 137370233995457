@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Maven+Pro:wght@400..900&display=swap");
html {
  font-size: 62.5%;
  /* 1rem=10px 10/16=62.5% */
  height: 100%;
  scroll-behavior: smooth;
}

* {
  font-family: "Source Sans Pro", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow: hidden; */
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #000 !important;
}
