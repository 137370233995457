.sidebar-top {
    background: transparent;
    position: fixed;
    z-index: 2;
    margin: 0;
    width: 100%;
    padding: 1.05rem 1.4rem;

    .sidebar-links {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }
  
    .sidebar-link,
    .sidebar-link-logo,
    .sidebar-link-login {
      list-style: none;
    }
  
    .sidebar-link-logo {
      // margin: 8px 35px 0 0;
    }
  
    .sidebar-link-logo > a > img {
      height: 4.5rem;
    }
  
    .bars {
      font-size: 4rem;
      color: white;
      width: auto;
      border: none;
    }

    .sidebar-link-login {
      color: #ffffff;
      font-weight: bold;
      font-size: 1.75rem;
    }
  }
  
  .sidebar-link.bars .sidebar-link > a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .sidebar-link {
    list-style: none;
    width: 100%;
    // border-top: 1px solid white;
  }

  
  
  .visible-enter {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translate(-100%);
    background: #2A2A2A;
    // margin-top: -25px;
    height: 100vh;
    width: 60%;
    position: fixed;
  
    .sidebar-link {
      height: 50%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 2.8rem;
    }
  
    a {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
    }
  
    a.active {
      color: #5be1b6;
    }
  }
  
  .visible-enter-active {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translate(0);
    transition: transform 0.3s linear;
    // margin-top: -25px;
    height: 100vh;
    width: 60%;
    position: fixed;
  
    .sidebar-link {
      height: 50%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 2.8rem;
    }
  
    a {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
    }
  
    a.active {
      color: #5be1b6;
    }
  }
  
  .visible-enter-done {
    z-index: 100;
    display: flex;
    width: 75%;
    flex-direction: column;
    justify-content: space-around;
    background: #2A2A2A;
    position: fixed;
    // margin-top: -25px;
    height: 100vh;
  
    .sidebar-link {
      height: 50%;
      display: flex;
      align-items: center;
      padding-left: 2.8rem;
    }
  
    a {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
    }
  
    a.active {
      color: #5be1b6;
    }
  }

  
  
  .visible-exit {
    z-index: 100;
    transform: translate(0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #2A2A2A;
    position: fixed;
    // margin-top: -25px;
    height: 100vh;
    width: 60%;
  
    .sidebar-link {
      height: 50%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 2.8rem;
    }
  
    a {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
    }
  
    a.active {
      color: #5be1b6;
    }
  }
  
  .visible-exit-active {
    z-index: 100;
    width: 60%;
    transform: translate(-100%);
    transition: transform 0.3s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #2A2A2A;
    position: fixed;
    // margin-top: -25px;
    height: 100vh;
  
    .sidebar-link {
      height: 50%;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 2.8rem;
    }
  
    a {
      text-decoration: none;
      color: white;
      font-size: 1.75rem;
    }
  
    a.active {
      color: #5be1b6;
    }
  }

  .sidebar-link-products-section {
    height: 21rem !important;
    display: flex;
    // justify-content: center;
    align-items: center;
    padding-left: 2.8rem;
  }
@primary-color: #3D87F1;